import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import UserNoUserNamePage from './ProductInfo/UserNoUserNamePage';
import ExtraInfoPage from './ProductInfo/ExtraInfoPage';
import ServiceNoHPage from './ProductInfo/ServiceNoHPage';
import ServiceNoSPage from './ProductInfo/ServiceNoSPage';
import CashFlowPage from './ProductInfo/CashFlowPage';
import EquipPage from './ProductInfo/EquipPage';
import UserIdUserNamePage from './ProductInfo/UserIdUserNamePage';
import UserNoUserNameNoBtnPage from './ProductInfo/UserNoUserNameNoBtnPage';
import * as callApiUtil from '../../utils/callApiUtil';

class ProdInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      // 顯示頁面
      isLoading: false,
      page: '',
      prodInfo: [],
      title: '',
      prodName: '',
      prodId: this.props.prodId,
      userNo: this.props.userNo,
      extraInfo: this.props.extraInfo,
      acctId: '',
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    let prodId = new URLSearchParams(this.props.location.search).get('prodId');
    let userNo = new URLSearchParams(this.props.location.search).get('userNo');
    let extraInfo = new URLSearchParams(this.props.location.search).get('extraInfo');
    let acctId = new URLSearchParams(this.props.location.search).get('acctId');
    this.setState({
      acctId: acctId,
      prodId: prodId,
      userNo: userNo,
    });

    let queryProdServiceCustInfoVin = {
      productId: prodId,
      userNo: userNo,
      extraInfo: extraInfo,
    };
    // 打API撈取資料
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryProdServiceProdInfo.action', queryProdServiceCustInfoVin)
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({
            isLoading: false,
            prodInfo: response.prodInfo,
            prodName: response.prodName,
            title: response.title,
          });

          //將頁面高度傳至iframe鑲嵌頁面
          this.handleClick();
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '產品服務作業查詢',
              title: '查詢失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    //將頁面高度傳至iframe鑲嵌頁面
    this.handleClick();
  };

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(
        <React.Fragment>
          <tr className='bg-white' key={key + value}>
            <td className='w-40 text-center border-gray'>{key}</td>
            <td className='w-60 p-3 border-gray'>{value}</td>
          </tr>
        </React.Fragment>
      );
    }
    return result;
  };

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };

  render() {
    const component = (e) => {
      switch (e) {
        case '007/1807':
        case '070':
        case '080':
        case '449':
        case 'AudioConference':
        case 'VASP':
        case 'OnnetTone':
          return (
            <UserNoUserNamePage
              prodInfo={this.state.prodInfo}
              title={this.state.title}
              prodId={this.state.prodId}
              extraInfo={this.props.extraInfo}
              userNo={this.props.userNo}
              acctId={this.state.acctId}
              prodName={this.state.prodName}
            />
          );
        case 'IELC':
        case 'IPLC':
        case 'IPVPN':
        case 'DLC':
        case 'ELC':
        case 'ELAN':
        case 'Wet segment':
          return <ExtraInfoPage prodInfo={this.state.prodInfo} title={this.state.title}></ExtraInfoPage>;
        case 'ELK':
          return <ServiceNoHPage prodInfo={this.state.prodInfo} title={this.state.title}></ServiceNoHPage>;
        case 'ADSL':
        case 'IPTransit':
        case 'SuperLink':
          return <ServiceNoSPage prodInfo={this.state.prodInfo} title={this.state.title}></ServiceNoSPage>;
        //顯示編號及名稱
        case 'fetAzure':
        case 'fetAzureStack':
        case 'fetVmware':
        case 'fetOffice365':
        case 'fetAwsCloud':
        case 'line':
          return (
            <UserNoUserNameNoBtnPage prodInfo={this.state.prodInfo} title={this.state.title}></UserNoUserNameNoBtnPage>
          );
        //顯示證號及名稱
        case 'AWS':
        case 'cloud':
        case 'fetHealth':
        case 'fetCdn':
        case 'GoogleCloud':
        case 'fetyc':
          return <UserIdUserNamePage prodInfo={this.state.prodInfo} title={this.state.title}></UserIdUserNamePage>;
        // 設備銷售
        case 'EQUIPMENT_ITEM':
          return <EquipPage prodInfo={this.state.prodInfo} title={this.state.title}></EquipPage>;
        // 金流應用
        case 'cashflow':
          return (
            <CashFlowPage
              userNo={this.state.userNo}
              prodId={this.state.prodId}
              prodName={this.state.prodName}
              prodInfo={this.state.prodInfo}
              title={this.state.title}></CashFlowPage>
          );
        default:
          <div />;
      }
    };
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='title mt-4'>產品基本資料</div>
        <React.Fragment>{component(this.state.prodName)}</React.Fragment>
      </div>
    );
  }
}

export default withRouter(ProdInfoPage);
