import React, { Fragment } from 'react';
import { Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Banner from '../../components/partials/banner/Banner';
import Helmet from 'react-helmet';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Dropdown from '../../components/Dropdown';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import TransactionApplication from '../resultController/TransactionApplication';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import * as dateFormat from '../../utils/dateFormatUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class ApplyAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPopOut: false, //是否彈出確認框
      submitRemind: false, //送出申請前的提醒
      isRecommendCheck: false, //是否顯示推薦人
      applyTrustee: false, //是否申請受任人
      isMailed: false, // 是否已經送出申請。#4882 若寄出確認信，不可再回到填寫資料頁，且button 確認送出改為disabled
      //當前頁數
      step: 1,
      //首頁勾選同意會員條款後才可跳轉下一頁
      canSubmit: false,
      date: null,
      //證號類別
      rocIdTypeOption: [
        { value: '1', text: '身分證字號' },
        { value: '3', text: '護照號碼' },
      ],
      //欄位驗證訊息
      errMsg: {
        // 公司基本資料
        companyName: '',
        companyTel: '',
        userId: '',
        applyName: '',
        applyRocid: '',
        recommendCode: '',
        recommendName: '',
        // 受任人資料
        trusteeName: '',
        trusteeTitle: '',
        trusteeEmail: '',
        trusteeFox: '',
        trusteeMsisdn: '',
        trusteePhone: '',
        trusteeRocid: '',
        trusteeLoginId: '',
        // 第一代理人資料
        mainName: '',
        mainTitle: '',
        mainEmail: '',
        mainFox: '',
        mainMsisdn: '',
        mainPhone: '',
        mainRocid: '',
        mainLoginId: '',
      },
      userIdExistErrMsg: '',
      //表格資料
      form: {
        // 公司基本資料
        companyName: { value: '', text: '' },
        companyTel: { value: '', text: '' },
        userId: { value: '', text: '' },
        applyName: { value: '', text: '' },
        applyRocid: { value: '', text: '' },
        applyRocidType: { value: '', text: '' },
        recommendName: { value: '', text: '' },
        recommendCode: { value: '', text: '' },
        // 第一代理人資料
        mainName: { value: '', text: '' },
        mainTitle: { value: '', text: '' },
        mainEmail: { value: '', text: '' },
        mainFox: { value: '', text: '' },
        mainMsisdn: { value: '', text: '' },
        mainPhone: { value: '', text: '' },
        mainRocid: { value: '', text: '' },
        mainRocidType: { value: '', text: '' },
        mainLoginId: { value: '', text: '' },
        // 受任人資料
        sameAsMain: { value: false, text: '' }, //受任人資料是否同第一代理人
        trusteeName: { value: '', text: '' },
        trusteeTitle: { value: '', text: '' },
        trusteeEmail: { value: '', text: '' },
        trusteeFox: { value: '', text: '' },
        trusteeMsisdn: { value: '', text: '' },
        trusteePhone: { value: '', text: '' },
        trusteeRocId: { value: '', text: '' },
        trusteeRocIdType: { value: '', text: '' },
        trusteeLoginId: { value: '', text: '' },
      },
      trusteeMainInfo: [
        {
          itemInfo: '帳號管理與資料異動',
          trustee: true,
          main: true,
        },
        {
          itemInfo: '帳務資訊(帳單明細瀏覽、報表、電信發票)',
          trustee: true,
          main: true,
        },
        {
          itemInfo: '第一代理人(管理者)變更及基本資料異動申請',
          trustee: true,
          main: false,
        },
        {
          itemInfo: '受任人資料變更申請書下載',
          trustee: true,
          main: false,
        },
        {
          itemInfo: '申請書上傳(限受任人權限)',
          trustee: true,
          main: false,
        },
      ],
      userIdType: null, // 統編/其他證號的類別
    };
  }
  componentDidMount() {
    // 初始化 redis
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/CustAccountApplyController/init.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
        this.setState(() => ({
          isLoading: false,
          date: dateFormat.format(new Date()),
        }));
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '帳號申請',
            title: '帳號申請',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回帳號申請頁',
                link: '/applyAccountPage',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  }
  //表格欄位驗證
  checkPage = (page, option) => {
    //初始化錯誤訊息欄
    let errMsg = Object.assign(this.state.errMsg);
    for (const key in errMsg) {
      errMsg[key] = '';
    }
    //非處於公司/第一代理人資料頁時，不需進行欄位驗證
    let isValid = true;
    //驗證公司資料頁
    if (page == 2) {
      if (!validation.notEmpty(this.state.form.companyName.value) || this.state.form.companyName.value.length > 50) {
        errMsg.companyName = '請輸入正確的公司/機關名稱/用戶名稱';
        isValid = false;
      }
      if (
        !validation.notEmpty(this.state.form.companyTel.value) ||
        !validation.phone(this.state.form.companyTel.value)
      ) {
        errMsg.companyTel = '請輸入正確的公司電話';
        isValid = false;
      }
      if (!validation.notEmpty(this.state.form.userId.value) || this.state.form.userId.value.length > 12) {
        errMsg.userId = '請輸入正確的統一編號/其他編號';
        isValid = false;
      }

      // 新增身分證驗證，阻擋個人客戶身分證號申請ECP，不可使用---20221006
      if (validation.notEmpty(this.state.form.userId.value) && validation.twId(this.state.form.userId.value)) {
        errMsg.userId = '不可輸入身分證字號，請輸入正確的統一編號/其他編號';
        isValid = false;
      }

      if (!validation.notEmpty(this.state.form.applyName.value) || this.state.form.applyName.value.length > 20) {
        errMsg.applyName = '請輸入正確的公司負責人';
        isValid = false;
      }

      if (
        validation.notEmpty(this.state.form.recommendName.value) &&
        !validation.notEmpty(this.state.form.recommendCode.value) &&
        this.state.isRecommendCheck
      ) {
        errMsg.recommendCode = '請輸入正確的推薦編號';
        isValid = false;
      }
      if (this.state.isRecommendCheck && !validation.notEmpty(this.state.form.recommendName.value)) {
        errMsg.recommendCode = '請點選推薦來源';
        isValid = false;
      } else {
        if (
          this.state.form.applyRocidType.value == 1 &&
          (!validation.notEmpty(this.state.form.applyRocid.value) || !validation.twId(this.state.form.applyRocid.value))
        ) {
          errMsg.applyRocid = '請輸入正確的公司負責人身分證號';
          isValid = false;
        }
        if (
          this.state.form.applyRocidType.value == 3 &&
          (!validation.notEmpty(this.state.form.applyRocid.value) ||
            !validation.alphaNumeric(this.state.form.applyRocid.value))
        ) {
          errMsg.applyRocid = '請輸入正確的公司負責人護照號碼';
          isValid = false;
        }
      }
    }
    //驗證受任人資料頁
    if (page == 4) {
      if (this.state.applyTrustee) {
        if (!validation.notEmpty(this.state.form.trusteeName.value) || this.state.form.trusteeName.value.length > 20) {
          errMsg.trusteeName = '請輸入正確的使用者姓名';
          isValid = false;
        }
        if (!validation.notEmpty(this.state.form.trusteeTitle.value) || this.state.form.trusteeTitle.value.length > 1) {
          errMsg.trusteeTitle = '請輸入正確的稱謂';
          isValid = false;
        }
        if (!validation.email(this.state.form.trusteeEmail.value)) {
          errMsg.trusteeEmail = '請輸入正確的連絡Email';
          isValid = false;
        }
        if (validation.notEmpty(this.state.form.trusteeFox.value)) {
          if (!validation.phone(this.state.form.trusteeFox.value)) {
            errMsg.trusteeFox = '請輸入正確的連絡傳真';
            isValid = false;
          }
        }
        if (!validation.mobile(this.state.form.trusteeMsisdn.value)) {
          errMsg.trusteeMsisdn = '請輸入正確的連絡行動電話';
          isValid = false;
        }
        if (
          !validation.notEmpty(this.state.form.trusteePhone.value) ||
          !validation.phone(this.state.form.trusteePhone.value)
        ) {
          errMsg.trusteePhone = '請輸入正確的連絡電話';
          isValid = false;
        }
        if (!validation.notEmpty(this.state.form.trusteeRocIdType.value)) {
          errMsg.trusteeRocid = '請選擇使用者證號類別';
          isValid = false;
        } else {
          if (
            this.state.form.trusteeRocIdType.value == 1 &&
            (!validation.notEmpty(this.state.form.trusteeRocId.value) ||
              !validation.twId(this.state.form.trusteeRocId.value))
          ) {
            errMsg.trusteeRocid = '請輸入正確的身份證字號';
            isValid = false;
          }
          if (
            this.state.form.trusteeRocIdType.value == 3 &&
            (!validation.notEmpty(this.state.form.trusteeRocId.value) ||
              !validation.alphaNumeric(this.state.form.trusteeRocId.value))
          ) {
            errMsg.trusteeRocid = '請輸入正確的護照號碼';
            isValid = false;
          }
        }
        if (
          !validation.notEmpty(this.state.form.trusteeLoginId.value) ||
          this.state.form.trusteeLoginId.value.length > 12 ||
          this.state.form.trusteeLoginId.value.length < 6 ||
          !validation.alphaNumeric(this.state.form.trusteeLoginId.value)
        ) {
          errMsg.trusteeLoginId = '受任人使用者帳號格式錯誤，請輸入6-12碼英文或數字';
          isValid = false;
        }
        // 0117 新增判斷, 第一代理人與受任人帳號不得相同
        if (
          validation.notEmpty(this.state.form.trusteeLoginId.value) &&
          this.state.form.mainLoginId.value == this.state.form.trusteeLoginId.value
        ) {
          errMsg.trusteeLoginId = '第一代理人使用者帳號與受任人使用者帳號不得相同，請重新輸入';
          isValid = false;
        }
      }
    }
    //驗證第一代理人資料頁
    if (page == 3) {
      if (!validation.notEmpty(this.state.form.mainName.value) || this.state.form.mainName.value.length > 20) {
        errMsg.mainName = '請輸入正確的使用者姓名';
        isValid = false;
      }
      if (!validation.notEmpty(this.state.form.mainTitle.value) || this.state.form.mainTitle.value.length > 1) {
        errMsg.mainTitle = '請輸入正確的稱謂';
        isValid = false;
      }
      if (!validation.email(this.state.form.mainEmail.value)) {
        errMsg.mainEmail = '請輸入正確的連絡Email';
        isValid = false;
      }
      if (validation.notEmpty(this.state.form.mainFox.value) && !validation.phone(this.state.form.mainFox.value)) {
        errMsg.mainFox = '請輸入正確的連絡傳真';
        isValid = false;
      }
      if (!validation.mobile(this.state.form.mainMsisdn.value)) {
        errMsg.mainMsisdn = '請輸入正確的連絡行動電話';
        isValid = false;
      }
      if (!validation.notEmpty(this.state.form.mainPhone.value) || !validation.phone(this.state.form.mainPhone.value)) {
        errMsg.mainPhone = '請輸入正確的連絡電話';
        isValid = false;
      }
      if (!validation.notEmpty(this.state.form.mainRocidType.value)) {
        errMsg.mainRocid = '請選擇使用者證號類別';
        isValid = false;
      } else {
        if (
          this.state.form.mainRocidType.value == 1 &&
          (!validation.notEmpty(this.state.form.mainRocid.value) || !validation.twId(this.state.form.mainRocid.value))
        ) {
          errMsg.mainRocid = '請輸入正確的身份證字號';
          isValid = false;
        }
        if (
          this.state.form.mainRocidType.value == 3 &&
          (!validation.notEmpty(this.state.form.mainRocid.value) ||
            !validation.alphaNumeric(this.state.form.mainRocid.value))
        ) {
          errMsg.mainRocid = '請輸入正確的護照號碼';
          isValid = false;
        }
      }
      if (
        !validation.notEmpty(this.state.form.mainLoginId.value) ||
        this.state.form.mainLoginId.value.length > 12 ||
        this.state.form.mainLoginId.value.length < 6 ||
        !validation.alphaNumeric(this.state.form.mainLoginId.value)
      ) {
        errMsg.mainLoginId = '第一代理人使用者帳號格式錯誤，請輸入6-12碼英文或數字';
        isValid = false;
      }
      // 0117 新增判斷, 第一代理人與受任人帳號不得相同
      if (this.state.form.mainLoginId.value == this.state.form.trusteeLoginId.value) {
        errMsg.mainLoginId = '第一代理人使用者帳號與受任人使用者帳號不得相同，請重新輸入';
        isValid = false;
      }
    }
    if (option != 'check') {
      this.setState(() => ({ errMsg: errMsg }));
    }
    return isValid;
  };
  //點選下一頁
  nextStep = () => {
    if (this.checkPage(this.state.step)) {
      document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      let nextStep = this.state.step + 1;
      this.setState({ step: nextStep });
    }
  };
  //指定跳轉某一頁
  changePage = (value) => {
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
    // 可跳回當前表單前的所有表單
    if (this.state.step >= value) {
      this.setState({ step: value });
    } else {
      // 可前往任意已填妥的表單與已填妥表單的下一個表單
      let step = this.state.step;
      let canChange = true;
      while (step < value && canChange) {
        canChange = this.checkPage(step++);
      }
      if (canChange) {
        this.setState({ step: value });
      }
    }
  };
  //變更資料輸入框之值
  onChange = (name, value) => {
    if (name == 'canSubmit') {
      this.setState({ canSubmit: !this.state.canSubmit });
    } else if (name == 'mainRocidType' || name == 'trusteeRocIdType' || name == 'applyRocidType') {
      let newForm = Object.assign(this.state.form);
      newForm[name].value = value.value;
      newForm[name].text = value.text;
      this.setState(() => ({ form: newForm }));
    } else if (name == 'userId') {
      let newForm = Object.assign(this.state.form);
      newForm[name].value = value;
      // 驗證UserIdType類型
      this.checkUserIdType();
      this.setState(() => ({ form: newForm, userIdExistErrMsg: '' }));
    } else {
      let newForm = Object.assign(this.state.form);
      newForm[name].value = value;
      this.setState({ form: newForm });
    }
    this.checkPage(this.state.step);
  };

  // 驗證統一編號/其他編號格式來判斷userIdType--phase2.0新增
  checkUserIdType = () => {
    let userIdType = null;
    if (validation.notEmpty(this.state.form.userId.value)) {
      // 驗證輸入的userId是否為統編
      if (validation.validTaxId(this.state.form.userId.value)) {
        userIdType = '2';
        // 驗證是否為身分證
      } else if (validation.twId(this.state.form.userId.value)) {
        userIdType = '1';
        // 其他
      } else {
        userIdType = '4';
      }
      this.setState(() => ({ userIdType: userIdType }));
    }
  };

  //申請受任人
  applyTrustee = () => {
    if (this.state.applyTrustee) {
      this.onChange('sameAsMain', false);
      // 清空受任人資料
      this.onChange('trusteeName', '');
      this.onChange('trusteeTitle', '');
      let rocIdType = { value: '', text: '' };
      this.onChange('trusteeRocIdType', rocIdType);
      this.onChange('trusteeRocId', '');
      this.onChange('trusteeEmail', '');
      this.onChange('trusteePhone', '');
      this.onChange('trusteeFox', '');
      this.onChange('trusteeMsisdn', '');
      this.onChange('trusteeLoginId', '');

      //清空錯誤訊息
      let errMsg = Object.assign(this.state.errMsg);
      for (const key in errMsg) {
        errMsg[key] = '';
      }
      this.setState(() => ({ errMsg: errMsg }));
    }
    this.setState({ applyTrustee: !this.state.applyTrustee });
  };
  //代理人帶入受任人資料
  trusteeAsMain = () => {
    this.onChange('sameAsMain', !this.state.form.sameAsMain.value);
    if (this.state.form.sameAsMain.value) {
      let mainName = this.state.form.mainName.value;
      let mainTitle = this.state.form.mainTitle.value;
      let rocIdType = this.state.form.mainRocidType;
      this.onChange('trusteeRocIdType', rocIdType);

      let mainRocid = this.state.form.mainRocid.value;
      let mainEmail = this.state.form.mainEmail.value;
      let mainPhone = this.state.form.mainPhone.value;
      let mainFox = this.state.form.mainFox.value;
      let mainMsisdn = this.state.form.mainMsisdn.value;

      this.onChange('trusteeName', mainName);
      this.onChange('trusteeTitle', mainTitle);
      this.onChange('trusteeRocId', mainRocid);
      this.onChange('trusteeEmail', mainEmail);
      this.onChange('trusteePhone', mainPhone);
      this.onChange('trusteeFox', mainFox);
      this.onChange('trusteeMsisdn', mainMsisdn);
    } else {
      // 取消帶入第一代理人資料 reset data
      this.onChange('trusteeName', '');
      this.onChange('trusteeTitle', '');
      let rocIdType = { value: '', text: '' };
      this.onChange('trusteeRocIdType', rocIdType);
      this.onChange('trusteeRocId', '');
      this.onChange('trusteeEmail', '');
      this.onChange('trusteePhone', '');
      this.onChange('trusteeFox', '');
      this.onChange('trusteeMsisdn', '');
    }
  };

  //轉換公司資料
  getCompanyBean = () => {
    return {
      cmpName: this.state.form.companyName.value,
      cmpTelNo: this.state.form.companyTel.value,
      cmpAddress1: '',
      cmpAddress2: '',
      userId: this.state.form.userId.value,
      userIdType: this.state.userIdType,
      cmpOwner: this.state.form.applyName.value,
      cmpOwnerId: this.state.form.applyRocid.value,
      cmpOwnerIdType: this.state.form.applyRocidType.value,
    };
  };
  //轉換受任人/第一代理人資料
  getCustAccountBean = (accntType) => {
    return {
      accntName: accntType == 1 ? this.state.form.mainName.value : this.state.form.trusteeName.value,
      gender: accntType == 1 ? this.state.form.mainTitle.value : this.state.form.trusteeTitle.value,
      accntEmail: accntType == 1 ? this.state.form.mainEmail.value : this.state.form.trusteeEmail.value,
      accntFaxNO: accntType == 1 ? this.state.form.mainFox.value : this.state.form.trusteeFox.value,
      accntMsisdn: accntType == 1 ? this.state.form.mainMsisdn.value : this.state.form.trusteeMsisdn.value,
      accntTelNO: accntType == 1 ? this.state.form.mainPhone.value : this.state.form.trusteePhone.value,
      accntID: accntType == 1 ? this.state.form.mainRocid.value : this.state.form.trusteeRocId.value,
      accntIdType: accntType == 1 ? this.state.form.mainRocidType.value : this.state.form.trusteeRocIdType.value,
      loginID: accntType == 1 ? this.state.form.mainLoginId.value : this.state.form.trusteeLoginId.value,
      userID: this.state.form.userId.value,
      userIDType: this.state.userIdType,
    };
  };
  // 送出資料到後端申請帳號
  submit = () => {
    if (this.checkPage(2) && this.checkPage(3) && this.checkPage(4)) {
      this.setState({ isLoading: true });
      //組成API input Value
      let vin = {
        companyBean: this.getCompanyBean(), // 公司基本資料
        custAccountBean: this.getCustAccountBean(1), // 第一代理人資料
        recommend: this.state.form.recommendName.value, //推薦人
        recommendCode: this.state.form.recommendCode.value.trim(), //推薦編碼
        applyTrustee: this.state.applyTrustee,
        trusteeAccountBean: this.getCustAccountBean(5), // 受任人資料
      };
      callApiUtil.callApi('/ECPAP/API/CustAccountApplyController/submit.action', vin).then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功

          if (response.userIdExist) {
            //當後端欄位驗證不通過，在對應欄位的錯誤提示訊息欄置入提示，跳轉至公司資料欄位頁。通過代表申請成功，跳轉至列印頁。
            this.setState(() => ({
              userIdExistErrMsg:
                '此用戶已是企業用戶專區會員。如用戶欲異動資料，則請用戶至企業用戶專區進行線上異動作業。',
            }));
            this.changePage(2);
          } else {
            this.changePage(5);
          }
          this.setState({ isLoading: false });
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '帳號申請',
              title: '帳號申請',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回帳號申請頁',
                  link: '/applyAccountPage',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
    }
  };
  popOut = () => {
    this.setState({ isPopOut: true });
  };
  //submit前彈出提醒視窗
  openRemind = () => {
    this.setState({ submitRemind: true });
  };
  // 送出列印請求
  print = () => {
    this.setState({ isPopOut: false });
    window.open(
      `/ECP/printApplyAccountPage`,
      '_blank',
      'height=800, width=800, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=0,location=no, status=no'
    );
  };

  send = () => {
    // 送出寄確認信請求
    this.setState({
      isLoading: true,
      submitRemind: false, // 關閉彈出視窗
    });
    callApiUtil.callApi('/ECPAP/API/CustAccountApplyController/mail.action').then((response) => {
      // 無論是否成功寄出帳號申請確認信，都不影響帳號申請流程，所以這邊顯示成功的結果頁
      // #4882 點選「確認送出」按鈕後，寄出信件，disabled 「確認送出」, 註冊畫面停在'提交申請書'(不顯示結果頁)
      this.setState({ isLoading: false, isMailed: true });
    });
  };

  changeRecommendCheck = (name, value) => {
    if (this.state.isRecommendCheck) {
      let newForm = Object.assign(this.state.form);
      newForm.recommendName.value = '';
      newForm.recommendCode.value = '';
      this.setState({
        form: newForm,
      });
    }

    this.setState({ isRecommendCheck: value }, () => this.checkPage(this.state.step));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>帳號申請</title>
        </Helmet>
        <Banner title='帳號申請' />
        <div className='bg-img'>
          <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
          <div className='container'>
            {this.state.step != 1 ? (
              <Nav fill variant='tabs' className='mt-4 bg-white'>
                <Nav.Item>
                  {/* #4882 確認送出後，註冊畫面停在'提交申請書' */}
                  <Nav.Link
                    className={`p-3 ${this.state.step == 2 ? 'selected' : ''}`}
                    onClick={() => this.changePage(2)}
                    disabled={this.state.isMailed}>
                    公司基本資料
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${this.state.step == 3 ? 'selected' : ''}`}
                    onClick={() => this.changePage(3)}
                    disabled={this.state.isMailed}>
                    公司第一代理人基本資料
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`p-3 ${this.state.step == 4 ? 'selected' : ''}`}
                    onClick={() => this.changePage(4)}
                    disabled={this.state.isMailed}>
                    受任人基本資料
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={`p-3 ${this.state.step == 5 ? 'selected' : ''}`} onClick={() => this.submit()}>
                    提交申請書
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            ) : null}

            {this.state.step == 1 ? (
              <React.Fragment>
                <div className='paper merged bg-white pb-4'>
                  <ul className='step-list mt-2'>
                    <li className='pb-md-3 row'>
                      <div className='circle'>1</div>
                      <div className='pt-3 col-10'>
                        <div className='mt-0 font-weight-bold h3'>填寫申請書</div>
                        <div className='mt-0 h5'>線上申請遠傳企業用戶會員，依序填入資料確認無誤後送出。</div>
                      </div>
                    </li>
                    <li className='pb-md-3 row'>
                      <div className='circle'>2</div>
                      <div className='pt-3 col-10'>
                        <div className='mt-0 font-weight-bold h3'>完成申請</div>
                        <div className='mt-0 h5'>
                          申請成功後，請列印申請書，於用印處蓋上公司大小章，並在帳號使用者欄位簽名後，檢附營利事業登記證及身分證影本。
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ol className='ml-5 mt-5 pt-4 font-normal'>
                    <div className='ml-4 mt-0 pl-1 font-weight-bold h3'>補充說明：</div>
                    <li className='p-1 h5'>
                      每一個企業用戶(同一個統一編號)可申請受任人/第一代理人各限一名之管理權限帳號，且帳號不能重覆。
                    </li>
                    <li className='p-1 h5'>
                      若原已申請平台帳號，而未新增受任人角色權限，可至常用表單區下載申請書並郵寄至『台北市114內湖區港墘路號220號10樓』予訂單管理組收。
                    </li>
                    <li className='p-1 h5'>
                      第一代理人建議設定總公司/總管理單位協助管理貴公司於遠傳企業用戶專區帳號同仁為第一代裡人。
                    </li>
                    <li className='p-1 h5'>
                      {/* 受任人及第一代理人可設定或異動多位第二代理人(子帳號)，並可設定其可使用之帳務類及非帳務類權限。 */}
                      受任人／第一代理人權限說明。
                      <div className='mt-3 w-80'>
                        <table className='w-100 mt-3'>
                          <tbody>
                            <tr className='bg-white'>
                              <td className='w-60 text-center border-gray'>服務項目</td>
                              <td className='w-20 text-center border-gray'>受任人</td>
                              <td className='w-20 text-center border-gray'>第一代理人</td>
                            </tr>
                            {this.state.trusteeMainInfo.map((item, index) => {
                              return (
                                <tr className='bg-white' key={'all' + index}>
                                  <td className='w-60 text-center border-gray'>{item.itemInfo}</td>
                                  <td className='w-20 text-center border-gray'>
                                    {item.trustee ? (
                                      <FontAwesomeIcon icon={faCheck} size={'2x'} color='#5f7b80' />
                                    ) : null}
                                  </td>
                                  <td className='w-20 text-center border-gray'>
                                    {item.main ? <FontAwesomeIcon icon={faCheck} size={'2x'} color='#5f7b80' /> : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li className='p-1 h5'>
                      <a
                        className='pointer h5'
                        onClick={() => window.open(process.env.PUBLIC_URL + '/registerProblemPage')}>
                        常見問題
                      </a>
                    </li>
                  </ol>
                </div>
                <div className='row end'>
                  <Button
                    className={`mt-5 ${this.state.canSubmit ? '' : 'disabled'}`}
                    btnStyle='primary'
                    size='large'
                    onClick={this.nextStep}>
                    下一步
                  </Button>
                  <input
                    type='checkbox'
                    name='accountRule'
                    className='ml-4 mr-2'
                    id='accountRule'
                    onClick={() => this.onChange('canSubmit', '')}></input>
                  <label for='accountRule' className='checkbox-label'>
                    我同意遠傳
                    <a
                      href='#'
                      onClick={() => {
                        window.open(
                          process.env.PUBLIC_URL + '/agreementPage',
                          '_blank',
                          'height=800, width=800, resizable=0'
                        );
                      }}
                      rel='noreferrer'>
                      會員條款
                    </a>
                  </label>
                </div>
              </React.Fragment>
            ) : null}

            {this.state.step == 2 ? (
              <div className='bg-white pb-3'>
                <div className='title p-4 pt-5 pl-5'>公司基本資料</div>
                <div className='form-group mb-2 pl-2'>
                  <label className='h5 pl-3 ml-4'>申請日期 : {this.state.date}</label>
                </div>
                <div className='form-group pl-2'>
                  <label className='h5 pt-3 pl-3 ml-4'>申請事由 : 新增帳號</label>
                </div>
                <Formsy>
                  <Grid container spacing={6} className='mt-md-3 ml-5'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='companyName'
                        value={this.state.form.companyName.value}
                        required={true}
                        placeholder='請輸入欲申請的公司/機關名稱/用戶名稱'
                        label='公司/機關名稱/用戶名稱'
                        maxLength='50'
                        onChange={this.onChange}
                        failResultMsg={this.state.errMsg.companyName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} className='mt-md-3 ml-5'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='userId'
                        value={this.state.form.userId.value}
                        required={true}
                        placeholder='請輸入統一編號或其他證號'
                        label='統一編號/其他'
                        maxLength='12'
                        onChange={this.onChange}
                        failResultMsg={this.state.errMsg.userId + this.state.userIdExistErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} className='mt-md-3 ml-5'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='applyName'
                        value={this.state.form.applyName.value}
                        required={true}
                        label='公司負責人'
                        hintText='請輸入負責人完整名稱'
                        maxLength='20'
                        placeholder='請輸入欲申請的公司負責人姓名'
                        onChange={this.onChange}
                        failResultMsg={this.state.errMsg.applyName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} className='mt-md-3 ml-5 form-group'>
                    <Grid item xs={12} md={8}>
                      <label className='h5 ml-4 mb-3'>公司負責人證號</label>
                      <div className='row' style={{ alignItems: 'center' }}>
                        <Dropdown
                          className='ml-3 is-button col-4'
                          list={this.state.rocIdTypeOption}
                          label={
                            this.state.form.applyRocidType.text ? this.state.form.applyRocidType.text : '請選擇證號類別'
                          }
                          arrow={true}
                          hasCheck={false}
                          onChange={(e) => this.onChange('applyRocidType', e)}
                        />
                        <input
                          name='applyRocid'
                          className='col-7'
                          value={this.state.form.applyRocid.value}
                          maxLength='30'
                          onChange={(e) => this.onChange('applyRocid', e.target.value)}
                          placeholder='請輸入欲申請的公司負責人證號'
                        />
                        <span className='error-message ml-4'>{this.state.errMsg.applyRocid}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} className='mt-md-3 ml-5'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='companyTel'
                        value={this.state.form.companyTel.value}
                        required={true}
                        label='公司/室內電話'
                        hintText='請填入區碼，例如02-77100000'
                        maxLength='30'
                        placeholder='請輸入欲申請的公司/室內電話'
                        onChange={this.onChange}
                        failResultMsg={this.state.errMsg.companyTel}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} className='mt-md-3 ml-5 form-group '>
                    <Grid item xs={12} md={8}>
                      <input
                        className='h5 mb-3 ml-4'
                        type='checkbox'
                        checked={this.state.isRecommendCheck}
                        value={this.state.isRecommendCheck}
                        onChange={(e) => this.changeRecommendCheck('IsRecommendCheck', !this.state.isRecommendCheck)}
                      />
                      <label className='h5 mb-3 ml-2'>推薦來源</label>
                      {this.state.isRecommendCheck ? (
                        <Fragment>
                          <input
                            type='radio'
                            className='radio-button ml-4'
                            name='recommendName'
                            id='S'
                            value='S'
                            disabled={!this.state.isRecommendCheck}
                            checked={this.state.form.recommendName.value == 'S'}
                            onChange={(e) => this.onChange('recommendName', e.target.value)}></input>
                          <label htmlFor='S' className='h5'>
                            業務推薦編號
                          </label>
                          <input
                            type='radio'
                            className='radio-button ml-4'
                            name='recommendName'
                            value='C'
                            id='C'
                            disabled={!this.state.isRecommendCheck}
                            checked={this.state.form.recommendName.value == 'C'}
                            onChange={(e) => this.onChange('recommendName', e.target.value)}></input>
                          <label htmlFor='C' className='h5'>
                            客服推薦編號
                          </label>

                          <LabelInput
                            className='ml-4'
                            name='recommendCode'
                            disabled={!this.state.isRecommendCheck || !this.state.form.recommendName.value}
                            placeholder='請輸入推薦編號'
                            value={this.state.form.recommendCode.value}
                            onChange={this.onChange}
                            failResultMsg={this.state.errMsg.recommendCode}
                          />
                        </Fragment>
                      ) : null}
                    </Grid>
                  </Grid>
                </Formsy>

                <div className='ml-5 mt-5 row end'>
                  <Button
                    className='ml-5 mt-3 mb-3'
                    btnStyle='primary'
                    size='large'
                    onClick={this.nextStep}
                    disabled={!this.checkPage(this.state.step, 'check')}>
                    下一步
                  </Button>
                </div>
              </div>
            ) : null}

            {this.state.step == 4 ? (
              <div className='bg-white pb-3'>
                <div className='title p-4 pt-5 pl-5'>受任人基本資料</div>
                <Formsy>
                  <div className='d-flex'>
                    <Grid container spacing={6} className='mt-3 ml-5 col-4'>
                      <Grid item xs={12} md={8}>
                        <input
                          type='checkbox'
                          name='applyTrustee'
                          className='ml-4'
                          id='applyTrustee'
                          checked={this.state.applyTrustee}
                          onChange={() => this.applyTrustee()}></input>
                        <label for='applyTrustee' className='checkbox-label'>
                          申請受任人
                        </label>
                      </Grid>
                    </Grid>
                    {this.state.applyTrustee ? (
                      <Grid container spacing={6} className='mt-md-3'>
                        <Grid item xs={12} md={8}>
                          <input
                            type='checkbox'
                            name='sameAsMain'
                            className='ml-4'
                            id='sameAsMain'
                            checked={this.state.form.sameAsMain.value}
                            onChange={() => this.trusteeAsMain()}></input>
                          <label for='sameAsMain' className='checkbox-label'>
                            同第一代理人基本資料
                          </label>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteeName'
                          value={this.state.form.trusteeName.value}
                          required={true}
                          label='使用者姓名'
                          maxLength='20'
                          placeholder='請輸入使用者姓名'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteeName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5 form-group w-80'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 mb-5 ml-4 is-required'>稱謂</label>
                        <div className='text-input'>
                          <input
                            disabled={!this.state.applyTrustee}
                            type='radio'
                            value='M'
                            id='M'
                            name='trusteeTitle'
                            className='radio-button ml-4 '
                            checked={this.state.form.trusteeTitle.value == 'M'}
                            onChange={(e) => this.onChange('trusteeTitle', e.target.value)}
                          />
                          <label htmlFor='M' className='h5'>
                            先生
                          </label>
                          <input
                            disabled={!this.state.applyTrustee}
                            type='radio'
                            value='F'
                            id='F'
                            name='trusteeTitle'
                            className='radio-button ml-4'
                            checked={this.state.form.trusteeTitle.value == 'F'}
                            onChange={(e) => this.onChange('trusteeTitle', e.target.value)}
                          />
                          <label htmlFor='F' className='h5'>
                            女士
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 mb-md-4 ml-5 form-group'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 mb-3 ml-4 is-required'>身份證字號</label>
                        <div className='row' style={{ alignItems: 'center' }}>
                          <Dropdown
                            disabled={!this.state.applyTrustee}
                            className={`ml-3 is-button col-4 ${!this.state.applyTrustee ? 'disabled' : ''}`}
                            list={this.state.rocIdTypeOption}
                            label={
                              this.state.form.trusteeRocIdType.text
                                ? this.state.form.trusteeRocIdType.text
                                : '請選擇證號類別'
                            }
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('trusteeRocIdType', e)}
                          />
                          <input
                            disabled={!this.state.applyTrustee}
                            name='trusteeRocId'
                            className='col-7'
                            value={this.state.form.trusteeRocId.value}
                            maxLength='30'
                            onChange={(e) => this.onChange('trusteeRocId', e.target.value)}
                            placeholder='請輸入使用者身份證字號'
                          />
                          <span className='error-message ml-3'>{this.state.errMsg.trusteeRocid}</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteeEmail'
                          value={this.state.form.trusteeEmail.value}
                          required={true}
                          label='連絡Email'
                          maxLength='50'
                          placeholder='請輸入使用者連絡Email'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteeEmail}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteePhone'
                          value={this.state.form.trusteePhone.value}
                          required={true}
                          label='連絡電話'
                          maxLength='30'
                          placeholder='請輸入使用者連絡電話'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteePhone}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteeFox'
                          value={this.state.form.trusteeFox.value}
                          required={false}
                          label='連絡傳真'
                          maxLength='30'
                          placeholder='請輸入使用者連絡傳真'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteeFox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteeMsisdn'
                          value={this.state.form.trusteeMsisdn.value}
                          required={true}
                          label='連絡行動電話'
                          maxLength='10'
                          placeholder='請輸入使用者連絡行動電話'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteeMsisdn}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          disabled={!this.state.applyTrustee}
                          className='ml-4'
                          name='trusteeLoginId'
                          value={this.state.form.trusteeLoginId.value}
                          required={true}
                          label='受任人帳號'
                          maxLength='16'
                          placeholder='請輸入使用者帳號'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.trusteeLoginId}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
                <div className='ml-5 mt-5 row end'>
                  <Button
                    className='ml-5 mt-3 mb-3'
                    btnStyle='primary'
                    size='large'
                    onClick={this.submit}
                    disabled={!this.checkPage(this.state.step, 'check')}>
                    下一步
                  </Button>
                </div>
              </div>
            ) : null}

            {this.state.step == 3 ? (
              <div className='bg-white pb-3'>
                <div className='title p-4 pt-5 pl-5'>公司第一代理人基本資料</div>
                <Formsy>
                  <div>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainName'
                          value={this.state.form.mainName.value}
                          required={true}
                          label='使用者姓名'
                          maxLength='20'
                          placeholder='請輸入使用者姓名'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5 form-group w-80'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 mb-5 ml-4 is-required'>稱謂</label>
                        <div className='text-input'>
                          <input
                            type='radio'
                            value='M'
                            id='M'
                            name='mainTitle'
                            className='radio-button ml-4 '
                            checked={this.state.form.mainTitle.value == 'M'}
                            onChange={(e) => this.onChange('mainTitle', e.target.value)}
                          />
                          <label htmlFor='M' className='h5'>
                            先生
                          </label>
                          <input
                            type='radio'
                            value='F'
                            id='F'
                            name='gender'
                            className='radio-button ml-4'
                            checked={this.state.form.mainTitle.value == 'F'}
                            onChange={(e) => this.onChange('mainTitle', e.target.value)}
                          />
                          <label htmlFor='F' className='h5'>
                            女士
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 mb-md-4 ml-5 form-group'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 mb-3 ml-4 is-required'>身份證字號</label>
                        <div className='row' style={{ alignItems: 'center' }}>
                          <Dropdown
                            className='ml-3 is-button col-4'
                            list={this.state.rocIdTypeOption}
                            label={
                              this.state.form.mainRocidType.text ? this.state.form.mainRocidType.text : '請選擇證號類別'
                            }
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('mainRocidType', e)}
                          />
                          <input
                            name='applyRocid'
                            className='col-7'
                            value={this.state.form.mainRocid.value}
                            maxLength='30'
                            onChange={(e) => this.onChange('mainRocid', e.target.value)}
                            placeholder='請輸入使用者身份證字號'
                          />
                          <span className='error-message ml-3'>{this.state.errMsg.mainRocid}</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainEmail'
                          value={this.state.form.mainEmail.value}
                          required={true}
                          label='連絡Email'
                          maxLength='50'
                          placeholder='請輸入使用者連絡Email'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainEmail}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainPhone'
                          value={this.state.form.mainPhone.value}
                          required={true}
                          label='連絡電話'
                          maxLength='30'
                          placeholder='請輸入使用者連絡電話'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainPhone}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainFox'
                          value={this.state.form.mainFox.value}
                          required={false}
                          label='連絡傳真'
                          maxLength='30'
                          placeholder='請輸入使用者連絡傳真'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainFox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainMsisdn'
                          value={this.state.form.mainMsisdn.value}
                          required={true}
                          label='連絡行動電話'
                          maxLength='10'
                          placeholder='請輸入使用者連絡行動電話'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainMsisdn}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className='mt-md-3 ml-5'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='mainLoginId'
                          value={this.state.form.mainLoginId.value}
                          required={true}
                          label='第一代理人(管理者)帳號'
                          maxLength='16'
                          placeholder='請輸入使用者帳號'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg.mainLoginId}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
                <div className='ml-5 mt-5 row end'>
                  <Button
                    className='ml-5 mt-3'
                    btnStyle='primary'
                    size='large'
                    onClick={this.nextStep}
                    disabled={!this.checkPage(this.state.step, 'check')}>
                    下一步
                  </Button>
                </div>
              </div>
            ) : null}

            {/* 提交申請書 */}
            {this.state.step == 5 ? (
              <div className='bg-white'>
                <div className='container'>
                  <div className='pt-5 pb-4'>
                    <TransactionApplication
                      print={this.popOut}
                      remind={this.openRemind}
                      applyTrustee={this.state.applyTrustee}
                      isMailed={this.state.isMailed}></TransactionApplication>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* 彈出視窗 */}
        <Dialog open={this.state.isPopOut} onClose={() => this.setState({ isPopOut: false })} fullWidth maxWidth={'xs'}>
          <div className='bg-header'>
            <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
          </div>
          <DialogContent dividers={true}>
            <div className='h5 ml-3 my-4 is-text-bold'>
              請確認申請內容是否正確無誤，
              <br />
              若是請接續列印作業後按確認送出。
            </div>
          </DialogContent>
          <DialogActions>
            <Button btnStyle='primary' className='w5 my-2' onClick={() => this.print()} autoFocus>
              我知道了
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.submitRemind}
          onClose={() => this.setState({ submitRemind: false })}
          fullWidth
          maxWidth={'xs'}>
          <div className='bg-header'>
            <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
          </div>
          <DialogContent dividers={true}>
            <div className='h5 ml-3 my-4 is-text-bold'>
              提醒您，申請書下載用印後須於兩周內寄回，以利於後續帳號開通作業。
            </div>
          </DialogContent>
          <DialogActions>
            <Button btnStyle='primary' className='w5 my-2' onClick={() => this.send()} autoFocus>
              我知道了
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withRouter(ApplyAccountPage);
